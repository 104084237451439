




















































































































import { Component, Vue } from 'vue-property-decorator'
import { signUp, getUser } from '@/services/Identity'
import Button from '@/components/Button.vue'
import Alert from '@/components/Alert.vue'
import moment from 'moment'

@Component({
  components: {
    Button,
    Alert
  }
})
export default class SignUp extends Vue {
  loading: boolean = false
  termsAccepted: boolean = false
  name: string = ''
  email: string = ''
  password: string = ''
  repeatPassword: string = ''
  error: string | null = null

  get showSignupCouponHint(): boolean {
    return moment().isSameOrBefore(moment('2021-11-30'), 'date')
  }

  async signUp() {
    this.loading = true

    if (this.password !== this.repeatPassword) {
      this.error = 'Die Passwörter stimmen nicht überein.'
      this.loading = false
      return
    }

    try {
      const firebaseUser = await signUp(this.email, this.password)
      await firebaseUser.updateProfile({ displayName: this.name })
      await firebaseUser.sendEmailVerification()
    } catch (error) {
      this.error = error.message
      this.loading = false
    }
  }
}
